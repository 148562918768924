/* 
    Created on : 18.4.2015, 12:49:11
    Author     : tvaliasek
*/

@import '_colors.scss';

@mixin generateVOffsets($howMany){
    @for $i from 1 through $howMany{
        .v-offset-top-#{$i} { margin-top: #{$i}em; }
        .v-offset-bottom-#{$i} { margin-bottom: #{$i}em; }
    }
}

@mixin list-none{
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

@mixin bg-gradient(){
    background-image: linear-gradient(30deg, $light-pink 0%, $orange 100%);
    background-color: #fff;
}

@mixin rotate-img($degs){
    &.rotate#{$degs} > img{
        transform: rotate(#{$degs}deg);
        transition: transform 100ms linear 0s;
    }
    
    &.rotate-#{$degs} > img{
        transform: rotate(-#{$degs}deg);
        transition: transform 100ms linear 0s;
    }
}
