/* 
    Created on : 16.4.2015, 14:51:43
    Author     : valiasek
*/

@import "_custom_mixins.scss";
@import "_colors.scss";

body{
    @include bg-gradient;
    min-height: 100vh;
    min-width: 100vw;
    overflow-x: hidden;
    color: white;
}

#logo-link{
    display: none;
}

@media(min-height: 620px){
    #logo-link{
        position: absolute;
        top: 2em;
        left: 50%;
        margin-left: -97px;
        display: inline-block;
        outline: none !important;
    }
}

.vertical-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;

    label{
        color: white;
    }

    #login{
        
        .links{
            margin: 1em 0em;
            > a {
                text-decoration: none;
            }
            
            & > a:nth-child(2){
                float: right;
            }
        }
        
        input:not(.btn){
            background-color: rgba(white, 0.1);
            color: white;
            border-color: white;
        }

        input[type="text"]:focus, input[type="password"]:focus{
            box-shadow: 0px 0px 0px transparent, inset 0px 0px 1px 2px rgba(white, 0.4);
        }

        .form-control::-moz-placeholder {
            color: rgba(white, 0.7);
            opacity: 1;
        }
        
        .form-control:-ms-input-placeholder {
            color: rgba(white, 0.7);
        }
        
        .form-control::-webkit-input-placeholder {
            color: rgba(white, 0.7);
        }

        a, a:visited, a:hover, a:focus, a:active {
            color: white;
        }

        hr{
            border-color: white;
        }

        #pwdReset{
            display: block;
            margin-top: 1em;
            position: relative;
            opacity: 0;
            z-index: -10;
            transition: opacity 200ms ease-in 0s;
            
            &.visible{
                z-index: 1;
                opacity: 1;
                transition: opacity 200ms ease-in 0s;
            }
        }
    }
}

.modal-header{
    @include bg-gradient;
}

.modal-footer, .modal-body, .modal, .modal-content{
    border-radius: 0px;
}

@import "_flash_message.scss";