/* 
    Created on : 4.5.2015, 21:30:13
    Author     : tvaliasek
*/

/*Flash Message*/
div.flash{
    position: fixed;
    display: block;
    z-index: 160;
    text-align: right;
    //top: 2em;
    margin-top: 1em;
    width: auto;
    color: #333;
    right: 1em;

    .flash-content{
        display: inline-block;
        background-color: white;
        padding: 1em 2em;
        //position: relative;
        position: relative;
        opacity: 1;
        transition: opacity 400ms ease-in 0s;
        text-align: center;
        right: 1.2em;
        top: 3em;
        font-size: 1.3em;
        z-index: 1600;
        border: 1px solid #ddd;
        box-sizing: border-box;
        margin-bottom: 0.5em;

        & > .flash-progress{
            display: block;
            position: absolute;
            bottom:0px;
            width:0px;
            left:0px;
            height: 2px;
            @include bg-gradient;
        }

        &.flash-fade{
            opacity: 0;
            transition: opacity 2s ease-out 4s;

            & > .flash-progress{
                width: 100%;
                transition: width 6s linear 0s;
            }

            &:hover, &:focus{
                opacity: 1;
                transition: none;
                & > .flash-progress{
                    width: 0px;
                    transition: none;
                }
            }
        }

        .flash-close{
            cursor: pointer;
            position: absolute;
            top: 12px;
            right: 12px;
        }
    }
}

@media(max-width:768px){
    div.flash{
        position: relative;
    }
}
