/* 
    Created on : 4.5.2015, 21:27:57
    Author     : tvaliasek
*/
$light-pink: #3FA2C3;//#CF4377;
$orange: #00AD83;//#F7864D;
$light-gray: #EFEFEF;
$box-shadow-color: #AAB2BD;
$darker-gray: #424A5D;
$gray-on-darker: #F2F2F2;
$green: #008504;

